"use strict";

$( function() {
    var modal = $( "body > #modal-divorce" );

    if ( !modal.length ) {
        return;
    }

    var URL = process.env.BACKEND_URL;
    var RECAPTCHA_ENABLED = "true" === process.env.GOOGLE_RECAPTCHA_ENABLED;
    var RECAPTCHA_KEY = process.env.GOOGLE_RECAPTCHA_KEY;

    var modals = $( "body > div.modal" );
    var modalFailConnection = modals.filter( "#modal-divorce-fail-connection" );
    var modalFailStatus = modals.filter( "#modal-divorce-fail-status" );
    var modalInvalid = modals.filter( "#modal-divorce-invalid" );
    var modalValid = modals.filter( "#modal-divorce-valid" );
    var modalSuccess = modals.filter( "#modal-divorce-success" );

    var progSteps = $( "#divorce-progress > .progress-step", modal );
    var forms = $( "form.divorce-step", modal );

    var btnNextStep = $( "#btn-divorce-next", modal );
    var btnPrevStep = $( "#btn-divorce-prev", modal );
    var btnReset = $( ".btn-divorce-reset", modal );
    var btnSubmit = $( ".btn-divorce-submit", modals );
    var btnSubmitBack = $( ".btn-divorce-submit-back", modals );
    var btnValidSubmit = $( "#btn-divorce-valid-submit", modalValid );

    var labels = $( "label", forms );
    var cbxFamilyCourtIssues = $( "#gerichtssachen", forms );
    var btnLastCommonAddress = $( "[name=\"letzteGemeinsameAdresse.select\"]", forms ).parent();
    var selectChildCount = $( "#select-child-count", forms );
    var templateChild = $( "#child-template", forms );
    var btnAddRemChild = $( ".child-up, .child-down", forms );

    var currentStep = 0;
    var lastStep = -1;
    var maxStep = forms.length - 1;

    var validatedData = null;
    var submitting = false;

    var recaptcha = {
        id: -1,
        status: false,
        isValid: function() {
            return this.status || !RECAPTCHA_ENABLED;
        }
    };

    /*
    var testConnection = function() {
        var test = $.ajax( {
            type: "OPTIONS",
            url: URL
        } );

        test.done( function() {
            window.console.log( "BACKEND CONNECTION AVAILABLE" );
        } );

        test.fail( function() {
            window.console.log( "BACKEND CONNECTION FAILED" );
        } );
    };
    */

    var postData = function( data, submit ) {
        if ( typeof submit !== "boolean" ) {
            submit = false;
        }

        var mode = submit ? "submit" : "validate";
        var recaptchaResponse = "";

        if ( submit && RECAPTCHA_ENABLED && -1 < recaptcha.id ) {
            recaptchaResponse = "?g-recaptcha-response=" + grecaptcha.getResponse( recaptcha.id );
        }

        data = mergeToDto( data );
        data = JSON.stringify( data );

        return $.ajax( {
            type: "POST",
            url: URL + mode + recaptchaResponse,
            data: data,
            dataType: "json",
            contentType: "application/json;charset=UTF-8"
        } );
    };

    var validateData = function( data ) {
        return postData( data, false );
    };

    var submitData = function( data ) {
        return postData( data, true );
    };

    var validateInput = function( input ) {
        if ( !( input instanceof jQuery ) || !input.length ) {
            return;
        }

        var step = currentStep;
        var data = getInputData( input );
        var post = validateData( data );

        post.done( function( response ) {
            markInputs( response.fieldErrors, data );
            updateProgress( step );
        } );
    };

    var validateForm = function() {
        var inputs = getInputs( forms );
        var data = {};

        submitting = true;
        updateModal();

        inputs.each( function() {
            Object.assign( data, getInputData( $( this ) ) );
        } );

        var post = validateData( data );

        post.done( function( response ) {
            if ( response.success ) {
                validatedData = data;
                bsModalSwitch( modalValid );
            } else {
                validatedData = null;
                recaptcha.status = false;

                bsModalSwitch( modalInvalid, function() {
                    markInputs( response.fieldErrors, data );
                    updateProgress();
                } );
            }
        } );

        post.fail( handleFail );

        post.always( function() {
            submitting = false;
            updateModal();
        } );
    };

    var submitForm = function() {
        if ( submitting ) {
            return;
        }

        submitting = true;
        updateModalValid();

        var post = submitData( validatedData );

        post.done( function( response ) {
            validatedData = null;
            recaptcha.status = false;

            if ( response.success ) {
                bsModalSwitch( modalSuccess );
                resetForms();
            } else {
                bsModalSwitch( modalInvalid );
            }
        } );

        post.fail( handleFail );

        post.always( function() {
            submitting = false;
            updateModalValid();
        } );
    };

    var handleFail = function( response ) {
        var status = response.status;
        validatedData = null;
        recaptcha.status = false;

        if ( 0 < status ) {
            modalFailStatus.find( "#status-code" ).text( status );
            bsModalSwitch( modalFailStatus );
        } else {
            bsModalSwitch( modalFailConnection );
        }
    };

    var markInputs = function( errors, data ) {
        var keys = Object.keys( data );

        for ( var k = 0; k < keys.length; k++ ) {
            var isValid = true;
            var key = keys[ k ];

            for ( var e = 0; e < errors.length; e++ ) {
                var error = errors[ e ].field;

                if ( error === key ) {
                    isValid = false;
                    break;
                }
            }

            /*
            window.console.log(
                ( isValid ? "    VALID: " : "NOT VALID: " ) +
                key + " = \"" + data[ key ] + "\""
            );
            */

            var input = $( document.getElementById( key ), forms );

            if ( !input.length ) {
                input = $( document.getElementsByName( key ), forms );
            }

            input.toggleClass( "is-invalid", !isValid );
        }
    };

    var getInputs = function( wrapper, getAll ) {
        if ( !( wrapper instanceof jQuery ) || !wrapper.length ) {
            return new jQuery();
        }

        if ( typeof getAll !== "boolean" ) {
            getAll = false;
        }

        var inputs = $( "input, select, textarea", wrapper );
        return getAll ? inputs : inputs.not( ".no-submit" );
    };

    var getInputData = function( input ) {
        var object = {};
        var value = "";
        var path = input.attr( "id" );
        var isNoVal = input.hasClass( "no-value" );

        if ( typeof path !== "string" ) {
            path = input.attr( "name" );
        }

        if ( !isNoVal && input.val() ) {
            value = input.val().trim();
        }

        if ( value.length && input.hasClass( "datepicker" ) ) {
            value =  moment.utc( value, [ "DD.MM.YYYY" ] ).format();
        } else if ( input.is( ":checkbox" ) ) {
            value = input.is( ":checked" ) ? "true" : "false";
        } else if ( input.is( ":radio:not(:checked)" ) ) {
            return object;
        }

        object[ path ] = value;
        return object;
    };

    var mergeToDto = function( data ) {
        var result = {};

        if ( typeof data !== "object" || data === null ) {
            return result;
        }

        var merge = function( target, source ) {
            var srcKeys = Object.keys( source );

            for ( var i = srcKeys.length - 1; 0 <= i; i-- ) {
                var srcKey = srcKeys[ i ];
                var isObj = typeof source[ srcKey ] === "object";
                var hasProp = target.hasOwnProperty( srcKey );

                if ( isObj && hasProp ) {
                    merge( target[ srcKey ], source[ srcKey ] );
                } else {
                    Object.assign( target, source );
                }
            }
        };

        var keys = Object.keys( data );

        for ( var i = 0; i < keys.length; i++ ) {
            var object = {};

            object[ keys[ i ] ] = data[ keys[ i ] ];
            merge( result, jsonify( object ) );
        }

        return result;
    };

    var jsonify = function( object ) {
        var result = {};

        if ( typeof object !== "object" ) {
            return result;
        }

        var key = Object.keys( object )[ 0 ];
        var value = object[ key ];
        var path = key.split( "." );

        for ( var i = path.length - 1; 0 <= i; i-- ) {
            var temp = {};
            var segment = path[ i ];
            var array = /\[\d*\]$/i.exec( segment );

            if ( array !== null ) {
                var arr = [];
                var pos = /\d+/i.exec( array );
                segment = segment.substring( 0, array.index );

                if ( pos !== null ) {
                    arr[ pos ] = object;
                }

                object = arr;
            } else if ( i === path.length - 1 ) {
                object = value;
            }

            temp[ segment ] = object;
            object = temp;
        }

        return object;
    };

    var createNewPicker = function( input ) {
        var formatOut = "DD.MM.YYYY";
        var formatIn = [ "DD-MM-YYYY", "YYYY-MM-DD" ];

        var setDate = function( picker ) {
            var value = moment( input.val(), formatIn );

            if ( value.isValid() ) {
                picker.setMoment( value );
            } else {
                picker.adjustDate();
            }
        };

        return new Pikaday( {
            field: input[ 0 ],
            position: false,
            bound: false,
            keyboardInput: false,
            firstDay: 1,
            minDate: moment().subtract( 100, "y" ).toDate(),
            maxDate: moment().toDate(),
            yearRange: 100,
            format: formatOut,
            i18n: {
                previousMonth: "Vorheriger Monat",
                nextMonth: "Nächster Monat",
                months: [
                    "Januar", "Februar", "März", "April", "Mai", "Juni",
                    "Juli", "August", "September", "Oktober", "November", "Dezember"
                ],
                weekdays: [
                    "Sonntag", "Montag", "Dienstag", "Mittwoch",
                    "Donnerstag", "Freitag", "Samstag"
                ],
                weekdaysShort: [
                    "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"
                ]
            },
            onDraw: function() {
                input.popover( "update" );
                setTimeout( function() {
                    if ( !input.is( ":focus" ) ) {
                        input.focus();
                    }
                }, 0 );
            },
            onOpen: function() {
                setDate( this );
            },
            onClose: function() {
                setDate( this );
            }
        } );
    };

    var bindPicker = function( elements ) {
        if ( !elements ) {
            return;
        }

        elements.each( function() {
            var picker, input = $( this );

            input.popover( {
                container: modal[ 0 ],
                boundary: $( ".modal-dialog", modal )[ 0 ],
                placement: "bottom",
                trigger: "manual",
                html: true,
                content: function() {
                    if ( !picker ) {
                        picker = createNewPicker( input );
                    }

                    if ( !picker.isVisible() ) {
                        picker.show();
                    }

                    return picker.el;
                }
            } ).on( "hidden.bs.popover", function() {
                if ( picker ) {
                    picker.hide();
                }
            } ).on( "keydown", function( e ) {
                var key = e.which || e.keyCode;
                var isEnter = 13 === key;
                var isEsc = 27 === key;

                if ( isEnter && picker ) {
                    e.preventDefault();
                    e.stopPropagation();
                    picker.hide();
                    picker.show();
                }

                if ( isEsc ) {
                    e.preventDefault();
                    e.stopPropagation();
                    input.popover( "hide" );
                }
            } ).on( "focusin", function() {
                if ( !picker ) {
                    input.popover( "show" );
                }
            } ).on( "focusout", function( e ) {
                var isPikaSelect = $( e.relatedTarget ).hasClass( "pika-select" );

                if ( !isPikaSelect ) {
                    input.popover( "hide" );
                }
            } ).on( "click", function( e ) {
                e.preventDefault();
                e.stopPropagation();

                if ( picker && !picker.isVisible() ) {
                    input.popover( "show" );
                }
            } );
        } );
    };

    var createNewChild = function( value ) {
        var index = value - 1;
        var child = templateChild.clone();
        var inputs = getInputs( child );
        var labels = $( "label", child );
        var badge = $( "<span>" );

        var relabel = function( label ) {
            return label.replace( "kinder", "kinder[" + index + "]" );
        };

        var reindex = function( tabindex ) {
            return parseInt( tabindex ) + ( index * inputs.length );
        };

        child.removeAttr( "id" );
        badge.addClass( "badge badge-primary mr-2" );
        badge.text( "Kind " + value );

        bindPicker( inputs.filter( ".datepicker" ) );

        labels.each( function( index ) {
            var label = $( this );
            var forAttr = relabel( label.attr( "for" ) );

            if ( 0 === index ) {
                label.prepend( badge );
            }

            label.attr( "for", forAttr );
        } );

        inputs.each( function() {
            var input = $( this );
            var idAttr = relabel( input.attr( "id" ) );
            var tabAttr = reindex( input.attr( "tabindex" ) );

            input.attr( "id", idAttr );
            input.attr( "tabindex", tabAttr );
        } );

        return child;
    };

    var updateModal = function() {
        btnSubmit.prop( "disabled", submitting );
        btnSubmit.toggleClass( "submitting", submitting );
    };

    var updateModalValid = function() {
        var rcptch = modalValid.find( "#recaptcha" );
        var showRecaptcha = -1 < recaptcha.id;
        var disableSubmit = !recaptcha.isValid() || submitting;

        rcptch.toggle( showRecaptcha );
        btnValidSubmit.prop( "disabled", disableSubmit );
        btnValidSubmit.toggleClass( "submitting", submitting );
        modalValid.modal( "handleUpdate" );
    };

    var updateChildCount = function( value, clearAll ) {
        if ( typeof value !== "number" ) {
            value = -1;
        }

        if ( typeof clearAll !== "boolean" ) {
            clearAll = false;
        }

        var options = selectChildCount.children( "option" ).length - 1;
        var btnAdd = btnAddRemChild.filter( ".child-up" );
        var btnRem = btnAddRemChild.filter( ".child-down" );
        var form = forms.filter( "#divorce-step-3" );
        var childs = form.children( ".child" );

        if ( value < 0 || options < value ) {
            value = 0;
        }

        if ( clearAll ) {
            childs.remove();
            childs = new jQuery();
        }

        if ( selectChildCount.val() !== value ) {
            selectChildCount.val( value );
        }

        btnAdd.prop( "disabled", value >= options );
        btnRem.prop( "disabled", value <= 0 );

        for ( var option = 1; option <= options; option++ ) {
            var child = childs.eq( option - 1 );

            if ( option <= value ) {
                if ( !child.length ) {
                    var footer = form.children( "#child-footer" );
                    footer.before( createNewChild( option ) );
                }
            } else {
                if ( child.length ) {
                    child.remove();
                }
            }
        }
    };

    var updateFamilyCourtIssues = function( checkbox ) {
        var wrapper = $( "#familiensachen-wrapper", forms );
        var inputs = getInputs( wrapper );

        if ( !( checkbox instanceof jQuery ) ) {
            checkbox = cbxFamilyCourtIssues;
        }

        var isChecked = checkbox.is( ":checked" );

        inputs.toggleClass( "no-value", !isChecked );
        inputs.prop( "disabled", !isChecked );
    };

    var updateLastCommonAddress = function( button, clearOther ) {
        var wrapper = $( "#letzteGemeinsameAdresse-wrapper", forms );
        var inputs = getInputs( wrapper );

        if ( !( button instanceof jQuery ) ) {
            button = btnLastCommonAddress.has( "input:checked" ).first();
        }

        if ( typeof clearOther !== "boolean" ) {
            clearOther = false;
        }

        var value = button.children( "input" ).val();
        var isRequester = "REQUESTER" === value;
        var isPartner = "PARTNER" === value;
        var isOther = !( isRequester || isPartner );

        inputs.each( function() {
            var input = $( this );
            var value = "";

            if ( isOther ) {
                if ( clearOther ) {
                    input.val( value );
                }

                return true;
            }

            var prefix = "";

            if ( isRequester ) {
                prefix = "anfrager.adresse";
            } else if ( isPartner ) {
                prefix = "partner.adresse";
            }

            var target = input.attr( "id" );
            var source = target.replace( "letzteGemeinsameAdresse", prefix );

            value = $( document.getElementById( source ) ).val();
            input.val( value );
        } );

        if ( !isOther || clearOther ) {
            inputs.removeClass( "is-invalid" );
        }

        inputs.toggleClass( "feedback", isOther );
        inputs.prop( "disabled", !isOther );
    };

    var getProgress = function() {
        var progress = [];

        progSteps.each( function() {
            progress.push( $( this ).hasClass( "error" ) ? 0 : 1 );
        } );

        return progress;
    };

    var updateProgress = function( step ) {
        var wrapper = forms;

        if ( typeof step === "number" ) {
            wrapper = forms.eq( step );
        } else {
            step = 0;
        }

        wrapper.each( function( index ) {
            var hasErrors = false;
            var inputs = getInputs( $( this ) );

            inputs.each( function() {
                var input = $( this );

                if ( !input.hasClass( "feedback" ) ) {
                    return true;
                }

                if ( input.hasClass( "is-invalid" ) ) {
                    hasErrors = true;
                    return false;
                }
            } );

            progSteps.eq( step + index ).toggleClass( "error", hasErrors );
        } );
    };

    var updateForms = function( scrollToTop ) {
        if ( typeof scrollToTop !== "boolean" ) {
            scrollToTop = true;
        }

        var isFirstPage = 0 === currentStep;
        var isLastPage = maxStep === currentStep;

        forms.hide();

        btnPrevStep.prop( "disabled", isFirstPage );
        btnNextStep.toggle( !isLastPage );
        btnSubmit.toggle( isLastPage );

        progSteps.each( function( idx ) {
            $( this ).toggleClass( "active", idx === currentStep );
        } );

        if ( 1 === currentStep ) {
            updateFamilyCourtIssues();
        }

        if ( 1 === currentStep || 0 === lastStep ) {
            updateLastCommonAddress();
        }

        forms.eq( currentStep ).show();
        modal.modal( "handleUpdate" );

        if ( scrollToTop ) {
            modal.scrollTop( 0 );
        }
    };

    var resetForm = function( step ) {
        if ( typeof step !== "number" ) {
            step = currentStep;
        }

        var form = forms.eq( step );
        var inputs = getInputs( form );
        var ignore = [ "letzteGemeinsameAdresse", "kinder" ];

        inputs.each( function() {
            var input = $( this );
            var name = input.attr( "name" );

            if ( -1 < ignore.indexOf( name ) ) {
                return true;
            }

            input.removeClass( "is-invalid" );

            if ( input.is( "select" ) ) {
                input = input.children( "option" ).first();
                input.prop( "selected", true );
                return true;
            }

            if ( input.is( ":checkbox" ) ) {
                input.prop( "checked", false );
                return true;
            }

            if ( input.is( ":radio" ) ) {
                var radioBtn = input.parent( ".btn" );
                var isRadioBtn = 0 < radioBtn.length;
                var radios = $( document.getElementsByName( name ), inputs );

                radios.each( function( index ) {
                    var radio = $( this );
                    var isFirst = 0 === index;

                    if ( isRadioBtn ) {
                        radio.parent().toggleClass( "active", isFirst );
                    }

                    radio.prop( "checked", isFirst );
                } );

                ignore.push( name );
                return true;
            }

            input.val( "" );
        } );

        if ( 0 === step ) {
            updateLastCommonAddress();
        }

        if ( 1 === step ) {
            cbxFamilyCourtIssues.prop( "checked", false );
            updateFamilyCourtIssues();

            btnLastCommonAddress.first().button( "toggle" );
            updateLastCommonAddress( null, true );
        }

        if ( 2 === step ) {
            updateChildCount( 1, true );
        }

        updateProgress( step );
    };

    var resetForms = function() {
        forms.each( function( index ) {
            resetForm( index );
        } );
    };

    var bsModalSwitch = function( targetModal, callback ) {
        if ( !( targetModal instanceof jQuery && targetModal.hasClass( "modal" ) ) ) {
            return;
        }

        var body = $( "body" );
        var currentModal = body.children( ".modal.show" ).first();

        var showTargetModal = function() {
            if ( typeof callback === "function" ) {
                callback.call( this );
            }

            targetModal.modal( "show" );
        };

        targetModal.one( "hidden.bs.modal", function() {
            var isPlainModal = $( this ).hasClass( "modal-trans" );
            var hasPlainModal = body.hasClass( "modal-plain" );

            body.removeClass( function() {
                var classes = "";

                if ( isPlainModal ) {
                    classes = "modal-switch modal-plain";
                } else if ( !hasPlainModal ) {
                    classes = "modal-switch";
                }

                return classes;
            } );
        } );

        body.addClass( "modal-switch" );

        if ( currentModal.length ) {
            var isPlainModal = targetModal.hasClass( "modal-trans" );

            if ( isPlainModal ) {
                targetModal.one( "shown.bs.modal", function() {
                    currentModal.modal( "hide" );
                } );

                body.addClass( "modal-plain" );
                showTargetModal();
            } else {
                currentModal.one( "hidden.bs.modal", function() {
                    showTargetModal();
                } );

                currentModal.modal( "hide" );
            }
        } else {
            showTargetModal();
        }
    };

    forms.popover( {
        container: modal[ 0 ],
        selector: "label.with-field-info > .field-info",
        boundary: $( ".modal-dialog", modal )[ 0 ],
        placement: "top",
        offset: "-25%p",
        trigger: "focus",
        html: true
    } );

    labels.each( function() {
        var label = $( this );
        var input = $( document.getElementById( label.attr( "for" ) ) );

        if ( !input.length ) {
            return true;
        }

        label.toggleClass( "required", input.is( ":required" ) );
    } );

    labels.on( "click", ".field-info", function( e ) {
        e.preventDefault();
    } );

    modal.on( "show.bs.modal", function() {
        updateForms();
    } );

    modal.on( "hide.bs.modal", function() {
        lastStep = currentStep;
        currentStep = 0;
    } );

    modal.on( "change", ":checkbox.feedback", function( ) {
        validateInput( $( this ) );
    } );

    modal.on( "focusout keyup", ".feedback:not(:checkbox)", function( e ) {
        if ( e.type === "keyup" ) {
            var key = e.which || e.keyCode;
            var isEnter = 13 === key;
            var isEsc = 27 === key;

            if ( !isEnter && !isEsc ) {
                return true;
            }
        }

        validateInput( $( this ) );
    } );

    var initRecaptcha = function() {
        if ( typeof grecaptcha === "undefined" || !RECAPTCHA_ENABLED ) {
            return;
        }

        modal.on( "show.bs.modal", function() {
            if ( -1 !== recaptcha.id ) {
                recaptcha.status = false;
                grecaptcha.reset( recaptcha.Id );

                updateModalValid();
                return;
            }

            var size = "normal";

            if ( $( window ).width() < 500 ) {
                size = "compact";
            }

            recaptcha.id = grecaptcha.render( "recaptcha", {
                "sitekey": RECAPTCHA_KEY,
                "theme": "light",
                "size": size,
                "tabindex": 1,
                "callback": function() {
                    recaptcha.status = true;
                    updateModalValid();
                },
                "expired-callback": function() {
                    recaptcha.status = false;
                    updateModalValid();
                },
                "error-callback": function() {
                    recaptcha.status = false;
                    updateModalValid();
                }
            } );

            updateModalValid();
        } );
    };

    progSteps.on( "click", function() {
        var idx = $( this ).index();

        if ( 0 <= idx && idx <= maxStep && idx !== currentStep ) {
            lastStep = currentStep;
            currentStep = idx;

            updateForms( false );
        }
    } );

    btnNextStep.on( "click", function() {
        if ( currentStep < maxStep ) {
            lastStep = currentStep;
            currentStep++;

            updateForms();
        }
    } );

    btnPrevStep.on( "click", function() {
        if ( currentStep > 0 ) {
            lastStep = currentStep;
            currentStep--;

            updateForms();
        }
    } );

    btnReset.on( "click", function() {
        resetForm( currentStep );
    } );

    btnSubmit.on( "click", function() {
        validateForm();
    } );

    btnSubmitBack.on( "click", function() {
        var progress = getProgress();
        var firstInvalidStep = progress.indexOf( 0 );

        if ( -1 < firstInvalidStep ) {
            currentStep = firstInvalidStep;
        } else {
            currentStep = lastStep;
        }

        bsModalSwitch( modal );
    } );

    btnValidSubmit.on( "click", function() {
        if ( !recaptcha.isValid() || submitting ) {
            return;
        }

        submitForm();
    } );

    cbxFamilyCourtIssues.on( "change", function() {
        updateFamilyCourtIssues( $( this ) );
    } );

    btnLastCommonAddress.on( "click", function() {
        updateLastCommonAddress( $( this ), true );
        updateProgress( currentStep );
    } );

    btnAddRemChild.on( "click", function() {
        var input = $( this );
        if ( input.is( ":disabled" ) ) {
            return;
        }

        var isAddBtn = input.hasClass( "child-up" );
        var currentValue = selectChildCount.val();
        var newValue = isAddBtn ? ++currentValue : --currentValue;

        selectChildCount.val( newValue );
        selectChildCount.trigger( "change" );
    } );

    selectChildCount.on( "change", function() {
        updateChildCount( parseInt( this.value ) );
        updateProgress( currentStep );
    } );

    $( document ).ready( function() {
        bindPicker( $( "input.datepicker", forms ) );
        updateChildCount( 1, true );
        initRecaptcha();
    } );
} );
