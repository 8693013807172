"use strict";

var toggleMenu = function( force ) {
    var body = $( "body" );
    var toggle = !body.hasClass( "menu-open" );

    if ( typeof force === "boolean" ) {
        toggle = force;
    }

    if ( toggle ) {
        $( window ).on( "keydown.menu", function( e ) {
            var key = e.keyCode || e.which;

            if ( 27 === key ) {
                toggleMenu( false );
            }
        } );
    } else {
        $( window ).off( ".menu" );
    }

    body.toggleClass( "menu-open", toggle );
};

var initHead = function() {
    var win = $( window );
    var header = $( "#header" );
    var wrapper = header.find( ".wrapper" );
    var lastViewHeight = 0;

    setTimeout( function() {
        if ( 80 <= win.scrollTop() ) {
            return;
        }

        wrapper.children( ".forward" ).addClass( "bounce" );
    }, 4000 );

    win.on( "resize init.af", function() {
        var viewHeight = $( this ).innerHeight();

        if ( lastViewHeight === viewHeight ) {
            return;
        }

        lastViewHeight = viewHeight;
        header.removeAttr( "style" );

        if ( viewHeight <= wrapper.outerHeight() ) {
            viewHeight = "auto";
        }

        header.css( { "height": viewHeight } );
        header.removeClass( "loading" );
    } );
};

var initMap = function() {
    var MAPS_ENABLED = "true" === process.env.GOOGLE_MAPS_ENABLED;
    var container = $( "#karte" );

    if ( !container.length || typeof google === "undefined" || !MAPS_ENABLED ) {
        container.hide();
        return;
    }

    var coordinates = {
        lat: 48.396959,
        lng: 9.959604
    };

    var icon = {
        url: container.data( "marker" ),
        scaledSize: new google.maps.Size( 40, 53 ),
        anchor: new google.maps.Point( 20, 53 )
    };

    var styles = [ {
        "featureType": "all",
        "stylers": [ { "saturation": -100 } ]
    }, {
        "featureType": "poi",
        "stylers": [ { "visibility": "off" } ]
    }, {
        "featureType": "transit",
        "stylers": [ { "visibility": "off" } ]
    } ];

    var map = new google.maps.Map( container[ 0 ], {
        center: coordinates,
        zoom: 13,
        mapTypeId: "roadmap",
        styles: styles,

        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
    } );

    var marker = new google.maps.Marker( {
        position: coordinates,
        map: map,
        icon: icon
    } );

    marker.addListener( "click", function() {
        $( "#modal-directions-info" ).modal( "show" );
    } );
};

var initNav = function() {
    var nav = $( "#navbar" );

    if ( !nav.length ) {
        return;
    }

    nav.on( "click", ".toggle-menu", function( e ) {
        e.preventDefault();
        e.stopPropagation();

        var self = $( this );

        if ( nav.hasClass( "fixed" ) ) {
            toggleMenu();
        } else {
            $( "body, html" ).animate( {
                scrollTop: nav.offset().top
            }, {
                duration: 320,
                complete: function() {
                    nav.addClass( "fixed" );
                    toggleMenu( true );
                }
            } );
        }

        if ( self.data( "toggle" ) === "modal" ) {
            $( self.attr( "href" ) ).modal( "show" );
        }
    } );

    var head = $( "#header" );

    if ( !head.length ) {
        nav.addClass( "fixed" );
        return;
    }

    var win = $( window );

    win.on( "scroll resize init.af", function() {
        var scroll = win.scrollTop();
        var pos = scroll - head.innerHeight();

        nav.toggleClass( "fixed", 0 <= pos );
    } );

    win.one( "init.af", function() {
        if ( this.history.replaceState ) {
            this.history.replaceState( "", document.title, this.location.pathname );
        }

         $( "body" ).removeClass( "hold-transitions" );
    } );
};

var initPageScroll = function() {
    $( "a.page-scroll" ).on( "click", function( e ) {
        e.preventDefault();
        e.stopPropagation();

        var body = $( "body" );
        var target, id = $( this ).attr( "href" ).replace( "/", "" );

        try {
            if ( id.length === 0 ) {
                throw "Can't scroll to element with empty ID. " +
                    "Trying to find first content element.";
            }

            target = $( id );
        } catch ( err ) {
            target = body.find( "> #main > section" ).first();
        }

        if ( !( target instanceof jQuery ) ) {
            return;
        }

        var modals = body.children( ".modal.show" );
        var menu = body.hasClass( "menu-open" );

        var scrollToPos = function() {
            $( "body, html" ).animate( {
                scrollTop: target.offset().top
            }, {
                duration: menu ? 0 : 500,
                complete: function() {
                    if ( menu ) {
                        toggleMenu( false );
                    }
                }
            } );
        };

        if ( target.length ) {
            if ( modals.length ) {
                modals.first().one( "hidden.bs.modal", function() {
                    scrollToPos();
                } );

                modals.modal( "hide" );
            } else {
                scrollToPos();
            }
        }
    } );
};

var initAppleFix = function() {
    var appleIos = navigator.userAgent.match( /iPhone|iPad|iPod/i );
    var appleMac = navigator.userAgent.match( /Macintosh/i );
    var documentPos = 0;

    if ( !appleMac && !appleIos ) {
        return;
    }

    $( ".d-ios-only" ).removeClass( "d-ios-only" );

    if ( !appleIos ) {
        return;
    }

    var win = $( window );
    var body = $( "body" );

    var fixBody = function() {
        documentPos = win.scrollTop();
        body.css( {
            position: "fixed",
            top: ( documentPos * -1 ),
            right: 0,
            left: 0
        } );
    };

    var freeBody = function() {
        body.removeAttr( "style" );
        win.scrollTop( documentPos );
    };

    $( ".modal" ).on( "show.bs.modal", fixBody )
        .on( "hidden.bs.modal", freeBody );
};

$( document ).ready( function() {
    initHead();
    initNav();
    initMap();
    initPageScroll();
    initAppleFix();

    $( window ).trigger( "init.af" );
} );
